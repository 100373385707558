<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import { getFragmentConfigOr } from "~/components/field-utils";
  import { ANY_TAG, filter_by_category } from "../store";
  import Dropdown from "./Dropdown.svelte";
  import { propsToCustomProperties } from "~/util/style";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext } = stores_context;

  $: ({ items } = $offers_ext);
  $: ({ tags } = $offers_ext);

  type Config = {
    textColor: string;
    backgroundColor: string;
    activeTextColor: string;
    activeBackgroundColor: string;
  }

  const config: Config = {
    textColor: 
      getFragmentConfigOr("textColor", fragment, "var(--brandColor1)"),
    backgroundColor: 
      getFragmentConfigOr("backgroundColor", fragment, "var(--brandColor2)"),
    activeTextColor: 
      getFragmentConfigOr("activeTextColor", fragment, "var(--brandColor1)"),
    activeBackgroundColor: 
      getFragmentConfigOr("activeBackgroundColor", fragment, "var(--brandColor1)")
  }

  function action(id: number) {
    filter_by_category.set(id);
  }

  $: t = $T;
</script>

{#if items && items.length}
  <div class="category-filter-container" style={`
    --textColor: ${config.textColor};
    --backgroundColor: ${config.backgroundColor};
    --activeTextColor: ${config.activeTextColor};
    --activeBackgroundColor: ${config.activeBackgroundColor};
  `}>
    <Dropdown color={config.activeTextColor} backgroundColor={config.backgroundColor}>
      <svelte:fragment slot="dropDownTitle">
          {$filter_by_category == ANY_TAG ? t`offers.allCategories` : tags.find(tag => tag.id == $filter_by_category)?.name}
      </svelte:fragment>
      <svelte:fragment slot="resetFilter">
        <div 
          class="dropdown-item"
          class:active={$filter_by_category == ANY_TAG}
        >
          <button 
            class="dropdown-item-button" 
            on:click={() => action(ANY_TAG)}
          >
            {t`offers.allCategories`}
          </button>
        </div>
      </svelte:fragment>
      <svelte:fragment slot="dropDownItems">
        {#if tags}
          {#each tags as category}
            <div 
              class="dropdown-item"
              class:active={$filter_by_category == category.id}
            >
              <button class="dropdown-item-button" 
                on:click={() => action(category.id)}>
                {category.name}
              </button>
            </div>
          {/each}
        {/if}
      </svelte:fragment>
    </Dropdown>
  </div>
{/if}

<style lang="scss">
  .category-filter-container {
    font-family: 'KHGrotesk', sans-serif;
    font-size: 16px;
    font-weight: 400;
    min-width: 200px;
    width: 100%;
    margin-right: 1rem;
    position: relative;
  }
  .dropdown-item-button{
    display: block;
    width: 100%;
    background: none;
    border: none;
  }
  button {
    border: none;
    background: none;
  }
  .dropdown-item {
    border-bottom: 1px solid var(--brandColor1);
    padding: 5px 10px;
    cursor: pointer;
    button{
      color: var(--brandColor1);
      text-align: left;
    }
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: var(--brandColor1);
      button {
        color: #fff !important;
        text-align: left;
      }
    }
  }

  @media (max-width: 768px) {
    .category-filter-container {
      min-width: 0;
      width: 45vw;
      margin-right: 0;
    }
  }
</style>
